import React, { useEffect, useState } from 'react';
import { SmileOutlined, UserOutlined } from '@ant-design/icons';
import { App, Result, Tabs, Typography, Upload } from 'antd';
import PresenterCircle from './components/PresenterCircle';
import UploadCircle from './components/UploadCircle';
import './index.scss';

export const Presenter = ({ form, imgToVideo }) => {
    const { state, actions } = imgToVideo;

    const { notification } = App.useApp();

    const [presenterPreview, setPresenterPreview] = useState('');

    useEffect(() => {
        const presenter = state.presenters.find((presenter) => presenter.user);

        setPresenterPreview(() => presenter?.url);
        form.setFieldValue('presenterUrl', presenter?.url || '');
    }, [state.presenters[0]?.url]);

    const onCustomPresenterUpload = async (info) => {
        const fd = new FormData();

        fd.append('presenter', info.file);

        try {
            const data = await actions.presenter.upload(fd);

            info.onSuccess(data);
        } catch (error) {
            info.onError(error);
        }
    };

    const onCustomPresenterRemove = async (presenter) => {
        try {
            await actions.presenter.remove(presenter._id);

            setPresenterPreview(() => '');
            form.setFieldValue('presenterUrl', '');

            notification.success({
                key: 'presenter-remove-success',
                placement: 'top',
                message: 'Custom presenter removed successfully'
            });
        } catch (error) {
            notification.error({
                key: 'presenter-remove-failed',
                placement: 'top',
                message: 'Custom presenter deletion failed'
            });
        }
    };

    return (
        <div className='img-to-video__presenter'>
            <div className='img-to-video__presenter__preview'>
                {presenterPreview ? (
                    <img
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                        src={presenterPreview}
                    />
                ) : (
                    <Result
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        icon={<SmileOutlined />}
                        title='Pick the presenter you like below'
                    />
                )}
            </div>
            <div className='img-to-video__presenter__menu'>
                <Tabs
                    defaultActiveKey='custom-presenter'
                    onChange={(key) => {
                        if (key === 'system-presenter') {
                            setPresenterPreview(() => state.presenters[0]?.url);
                            form.setFieldValue('presenterUrl', state.presenters[0]?.url);
                        } else {
                            const presenter = state.presenters.find((presenter) => presenter.user);

                            setPresenterPreview(() => presenter?.url);
                            form.setFieldValue('presenterUrl', presenter?.url || '');
                        }
                    }}
                    items={[
                        {
                            key: 'system-presenter',
                            label: (
                                <Typography.Title level={4}>
                                    <UserOutlined />
                                    Choose a Presenter
                                </Typography.Title>
                            ),
                            children: (
                                <div className='img-to-video__presenter__menu__items'>
                                    {state.presenters
                                        .filter((presenter) => !presenter.user)
                                        .map((presenter) => {
                                            return (
                                                <PresenterCircle
                                                    isSelected={presenterPreview === presenter.url}
                                                    key={presenter._id}
                                                    src={presenter.url}
                                                    alt={presenter.title}
                                                    onClick={() => {
                                                        setPresenterPreview(presenter.url);
                                                        form.setFieldValue('presenterUrl', presenter.url);
                                                    }}
                                                />
                                            );
                                        })}
                                </div>
                            )
                        },
                        {
                            key: 'custom-presenter',
                            label: (
                                <Typography.Title level={4}>
                                    <UserOutlined />
                                    {state.presenters.filter((presenter) => presenter.user).length
                                        ? 'Uploaded Presenter'
                                        : 'Upload Presenter'}
                                </Typography.Title>
                            ),
                            children: (
                                <div>
                                    <div className='img-to-video__presenter__menu__items'>
                                        <Upload
                                            accept='image/png, image/jpeg, image/jpg'
                                            showUploadList={false}
                                            maxCount={1}
                                            name='presenter'
                                            customRequest={onCustomPresenterUpload}
                                            onChange={(info) => {
                                                if (info.file.status === 'done') {
                                                    notification.success({
                                                        key: 'presenter-upload-success',
                                                        placement: 'top',
                                                        message: `Presenter ${info.file.name} uploaded successfully`
                                                    });
                                                } else if (info.file.status === 'error') {
                                                    notification.error({
                                                        key: 'presenter-upload-failed',
                                                        placement: 'top',
                                                        message: `Presenter ${info.file.name} upload failed.`
                                                    });
                                                }
                                            }}
                                        >
                                            <UploadCircle uploading={state.loader.presenter.upload} />
                                        </Upload>
                                        {state.presenters
                                            .filter((presenter) => presenter.user)
                                            .map((presenter) => {
                                                return (
                                                    <PresenterCircle
                                                        isSelected={presenterPreview === presenter.url}
                                                        isRemoving={state.loader.presenter.remove}
                                                        key={presenter._id}
                                                        src={presenter.url}
                                                        alt={presenter.title}
                                                        onClick={() => {
                                                            setPresenterPreview(presenter.url);
                                                            form.setFieldValue('presenterUrl', presenter.url);
                                                        }}
                                                        onRemove={() => onCustomPresenterRemove(presenter)}
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                            )
                        }
                    ]}
                />
            </div>
        </div>
    );
};
