import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Spin, Typography } from 'antd';

export const LoadingModal = ({ state, actions }) => {
    const history = useHistory();

    return (
        <Modal
            className='img-to-video__loader'
            closable
            centered
            open={state.modals.loadVideo.status}
            onCancel={() => actions.modals.loadVideo(false)}
            getContainer={() => document.querySelector('#app-layout')}
            footer={[
                <Button key='link' type='primary' onClick={() => history.push('/video/history')}>
                    Go to video library
                </Button>
            ]}
        >
            <Spin
                spinning={state.modals.loadVideo.isLoadingVideo}
                tip='Your video is being generated now, please wait'
                size='large'
            >
                <Typography.Title level={4}>{state.modals.loadVideo.text}</Typography.Title>
            </Spin>
        </Modal>
    );
};
