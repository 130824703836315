import { useImmerReducer } from 'use-immer';
import { initState, knowledgeBaseReducer } from './reducer';
import api from './services';

export const useKnowledgeBase = () => {
    const [state, dispatch] = useImmerReducer(knowledgeBaseReducer, initState);

    const document = {
        modal: (status, record) => {
            dispatch({
                type: 'DOCUMENT_MODAL',
                payload: { status, record }
            });
        },
        train: async (id) => {
            try {
                dispatch({
                    type: 'DOC_TRAIN_START'
                });

                const { data } = await api.knowledgeBaseService.document.train(id);

                dispatch({
                    type: 'DOC_TRAIN_END',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'DOC_TRAIN_ERROR'
                });
            }
        },
        remove: async (id) => {
            try {
                dispatch({
                    type: 'DOCUMENT_REMOVE_START'
                });

                await api.knowledgeBaseService.document.remove(id);
            } catch (error) {
                dispatch({
                    type: 'DOCUMENT_REMOVE_END'
                });
            }
        },
        create: async (payload) => {
            try {
                dispatch({
                    type: 'DOCUMENT_CREATE_START',
                    payload
                });

                const response = await api.knowledgeBaseService.document.create(payload);

                dispatch({
                    type: 'DOCUMENT_CREATE_END',
                    payload: response
                });
            } catch (error) {
                dispatch({
                    type: 'DOCUMENT_CREATE_END'
                });
            }
        },
        update: (payload) => {
            try {
                dispatch({
                    type: 'DOCUMENT_UPDATE',
                    payload
                });
            } catch (error) {
                dispatch({
                    type: 'DOCUMENT_UPDATE_END'
                });
            }
        },
        list: async (payload) => {
            dispatch({
                type: 'DOCUMENT_LIST_START',
                payload
            });

            const { data } = await api.knowledgeBaseService.document.list();

            try {
                dispatch({
                    type: 'DOCUMENT_LIST',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'DOCUMENT_LIST_END'
                });
            }
        }
    };

    const video = {
        modal: (status) => {
            dispatch({
                type: 'VIDEO_MODAL',
                payload: status
            });
        },
        remove: async (id) => {
            try {
                dispatch({
                    type: 'DOCUMENT_REMOVE_START'
                });

                await api.knowledgeBaseService.document.remove(id);
            } catch (error) {
                dispatch({
                    type: 'DOCUMENT_REMOVE_END'
                });
            }
        },
        train: async (id) => {
            try {
                dispatch({
                    type: 'VIDEO_TRAIN_START'
                });

                const { data } = await api.knowledgeBaseService.video.train(id);

                dispatch({
                    type: 'VIDEO_TRAIN_END',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'VIDEO_TRAIN_ERROR'
                });
            }
        },
        create: async (payload) => {
            try {
                dispatch({
                    type: 'VIDEO_CREATE_START',
                    payload
                });

                const response = await api.knowledgeBaseService.video.create(payload);

                dispatch({
                    type: 'VIDEO_CREATE_END',
                    payload: response
                });
            } catch (error) {
                dispatch({
                    type: 'VIDEO_CREATE_FAILED'
                });
            }
        },
        update: (payload) => {
            try {
                dispatch({
                    type: 'DOCUMENT_UPDATE',
                    payload
                });
            } catch (error) {
                dispatch({
                    type: 'DOCUMENT_UPDATE_END'
                });
            }
        },
        list: async (payload) => {
            dispatch({
                type: 'VIDEO_LIST_START',
                payload
            });

            const { data } = await api.knowledgeBaseService.video.list();

            try {
                dispatch({
                    type: 'VIDEO_LIST',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'VIDEO_LIST_END'
                });
            }
        }
    };

    const actions = {
        document,
        video
    };

    return {
        state,
        actions
    };
};
