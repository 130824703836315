import { notification } from 'antd';

export const beforeUpload = (file, options, validMimeTypes) => {
    if (!validMimeTypes.includes(file.type)) {
        notification.error({
            placement: 'top',
            message: `Valid image formats are: ${validMimeTypes.join(', ')}`
        });

        return false;
    }

    const isLtMb = file.size / 1024 / 1024 < options.maxFileSize;

    if (!isLtMb) {
        notification.error({
            placement: 'top',
            message: `Image must smaller than ${options.maxFileSize} MB!`
        });

        return false;
    }

    return true;
};
