import React from 'react';
import { Form, Input, Modal, notification, Typography } from 'antd';

export const AddVideo = ({ actions, state }) => {
    const [form] = Form.useForm();

    const onSubmit = async () => {
        try {
            const validFields = await form.validateFields();

            if (validFields.youtube) {
                await actions.video.create(validFields);
                await actions.video.modal(false);
                form.resetFields();
            }
        } catch (e) {
            notification.error({
                placement: 'top',
                message: 'Url is not valid'
            });
        }
    };
    return (
        <Modal
            title='Add new YouTube video'
            width={710}
            className='modal-component'
            centered
            open={state.video.modal}
            onCancel={() => actions.video.modal(false)}
            okText='Add Youtube Video'
            onOk={onSubmit}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Typography.Title level={3}>YouTube URL</Typography.Title>
            <Form form={form}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'This field is required.'
                        },
                        {
                            type: 'url',
                            message: 'This field must be a valid url.'
                        }
                    ]}
                    name='youtube'
                >
                    <Input type='url' placeholder='https://www.youtube.com/' />
                </Form.Item>
            </Form>
        </Modal>
    );
};
