import React from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Typography } from 'antd';
import { TabsComponent } from './components/Tabs';
import styles from './index.module.scss';

export default function KnowledgeBase({ match, history }) {
    const location = useLocation();

    const items = [
        {
            key: 'documents',
            label: 'Documents',
            children: <TabsComponent history={history} match={match} />
        },
        {
            key: 'videos',
            label: 'Videos',
            children: <TabsComponent history={history} match={match} />
        }
    ];

    const onChange = (key) => {
        history.push(`${match.path}/${key}`);
    };

    return (
        <div className={styles.knowledge_base}>
            <div className={styles.knowledge_base__heading}>
                <Typography.Title level={1}>Knowledge Base</Typography.Title>
                <Typography.Text>
                    Add Documents, Websites, Videos, and FAQs, Just Think will add it to it’s memory
                </Typography.Text>
            </div>
            <div className={styles.knowledge_base__body}>
                <Tabs activeKey={location.pathname.split('/')?.[2]} items={items} onChange={onChange} />
            </div>
        </div>
    );
}
