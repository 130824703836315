import { axios } from '../../../../services/http/axios';

const knowledgeBaseService = {
    document: {
        list: () => axios.get('/embeddings/document'),
        remove: (id) => axios.delete(`/embeddings/document/${id}`),
        train: (id) => axios.get(`/embeddings/document/${id}`),
        create: async (data) => {
            const headers = {
                'Content-Type': 'multipart/form-data',
                'x-access-token': localStorage.getItem('BrainstormAuthToken')
            };

            const response = await axios.post('/embeddings/document', data, {
                headers
            });

            return response.data;
        }
    },
    video: {
        list: () => axios.get('/embeddings/video'),
        remove: (id) => axios.delete(`/embeddings/video/${id}`),
        train: (id) => axios.get(`/embeddings/video/${id}`),
        create: async (data) => {
            const response = await axios.post('/embeddings/video', data);

            return response.data;
        }
    }
};

const api = {
    knowledgeBaseService
};

export default api;
