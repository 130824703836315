import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MenuOutlined, MoreOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import cn from 'classnames';
import JustThinkLogoFull from '../../assets/images/just-think-logo-blue-full-logo-yellow.png';
import { useBreakpoint } from '../../hooks/media';
import { useSearchParams } from '../../hooks/searchParams';
import { useLocalStorage } from '../../hooks/storage';
import ScrollToTop from '../../scrollToTop';
import { useAuthContext } from '../AuthPage/context';
import { BillingUpdate } from './components/BillingUpdate';
import { BillingUpdateModal } from './components/BillingUpdateModal';
import MenuDrawer from './components/Menu/Drawer/Drawer';
import { mainMenu, subMenu } from './components/Menu/Menu';
import MenuSider from './components/Menu/Sider/Sider';
import { ArtHistoryPage } from './pages/ArtHistoryPage';
import ArtPage from './pages/ArtPage/ArtPage';
import { BookPage } from './pages/BookPage';
import ChatPage from './pages/ChatPage/ChatPage';
import { ConversationProvider } from './pages/ChatPage/components/Conversation/context';
import DocumentEditor from './pages/DocumentPage/components/Editor';
import DocumentHistory from './pages/DocumentPage/components/History';
import { ImageTemplates } from './pages/ImageTemplates';
import ImageUpscalePage from './pages/ImageUpscalePage';
import KnowledgeBase from './pages/KnowledgeBase';
import LibraryPage from './pages/LibraryPage';
import BillingPage from './pages/SettingsPage/pages/BillingPage';
import OrganizationPage from './pages/SettingsPage/pages/OrganizationPage';
import ProfilePage from './pages/SettingsPage/pages/ProfilePage';
import TeamPage from './pages/SettingsPage/pages/TeamPage';
import SpeechHistoryPage from './pages/TextToSpeechPage/pages/HistoryPage';
import JustSpeakPage from './pages/TextToSpeechPage/pages/JustSpeakPage';
import ToolsPage from './pages/ToolsPage';
import { categoryRouteMapper } from './pages/ToolsPage/constants';
import ImageToVideoHistoryPage from './pages/VideoPage/pages/HistoryPage';
import ImageToVideoPage from './pages/VideoPage/pages/ImageToVideoPage';
import { SingleVideoPage } from './pages/VideoPage/pages/SingleVideoPage';
import { DashboardProvider } from './context';
import { useDashboard } from './hooks';
import './index.scss';

const { Header, Content } = Layout;

export default function DashboardPage({ history, match, location }) {
    const searchParams = useSearchParams();

    const dashboardContext = useDashboard();

    const [docToken, setDocToken] = useLocalStorage('DocToken');

    const ctrlRef = useRef(null);

    const categoryTitle = categoryRouteMapper[searchParams.get('category') || 'all-tools']?.tab;

    const auth = useAuthContext();

    useEffect(() => {
        (async () => {
            await dashboardContext.actions.toolsList();
        })();
    }, []);

    const { isMobile, isTable } = useBreakpoint();

    const [collapsed, setCollapsed] = useState(false);

    const [showMenu, setShowMenu] = useState(true);

    const {
        token: { colorBgContainer, colorBgLayout }
    } = theme.useToken();

    const onMenuItemClick = ({ key }) => history.push(key);

    const createSelectedKeys = (menuItems) => {
        const selectedKeys = [];

        function traverse(items) {
            items.forEach(({ key, children }) => {
                if (location.pathname === '/tools/chat' && key === '/tools') {
                    selectedKeys.push('/tools');
                }

                if (key === location.pathname) {
                    selectedKeys.push(key);
                }

                if (children) {
                    traverse(children);
                }
            });
        }

        traverse(menuItems);

        return selectedKeys;
    };

    const selectedKeys = createSelectedKeys(mainMenu);

    const menuItems = [
        {
            key: 'toggle',
            icon: <MenuOutlined />,
            className: 'app-layout-wrap__burger',
            onClick: () => {
                setCollapsed(!collapsed);
                setShowMenu(true);
            }
        }
    ];

    switch (location.pathname) {
        case '/tools':
            menuItems.push({
                key: 'site-category-name',
                className: 'app-layout-wrap__mob-category-name',
                label: <span>{categoryTitle}</span>
            });
            break;
        case '/settings/profile':
            menuItems.push({
                key: 'site-profile-name',
                className: 'app-layout-wrap__mob-category-name',
                label: <span>User Profile</span>
            });
            break;
        case '/settings/billing':
            menuItems.push({
                key: 'site-profile-name',
                className: 'app-layout-wrap__mob-category-name',
                label: <span>Billing</span>
            });
            break;
        case '/chat':
            menuItems.push(
                {
                    key: 'right-menu',
                    className: 'mob-icon-chat',
                    icon: <MoreOutlined />,
                    onClick: () => {
                        setCollapsed(!collapsed);
                        setShowMenu(false);
                    }
                },
                {
                    key: 'site-logo',
                    className: 'app-layout-wrap__mob-logo',
                    label: <img src={JustThinkLogoFull} alt='logo' />,
                    onClick: () => {
                        history.push('/chat');
                    }
                }
            );
            break;
        default:
            menuItems.push({
                key: 'site-logo',
                className: 'app-layout-wrap__mob-logo',
                label: <img src={JustThinkLogoFull} alt='logo' />,
                onClick: () => {
                    history.push('/chat');
                }
            });
    }

    return (
        <ConversationProvider>
            <DashboardProvider value={dashboardContext}>
                <Layout className={auth?.profile?.uiSettings?.darkMode ? 'app-dark-mode' : ''} id='app-layout'>
                    {isTable || isMobile ? (
                        <MenuDrawer
                            ctrlRef={ctrlRef}
                            history={history}
                            open={collapsed}
                            showMenu={showMenu}
                            onClose={() => setCollapsed(false)}
                            menuItems={mainMenu}
                            settings={subMenu}
                            onMenuItemClick={onMenuItemClick}
                            selectedKeys={selectedKeys}
                        />
                    ) : null}
                    {!isTable && !isMobile ? (
                        <MenuSider
                            collapsed={collapsed}
                            menuItems={mainMenu}
                            settings={subMenu}
                            onMenuItemClick={onMenuItemClick}
                            selectedKeys={selectedKeys}
                        />
                    ) : null}

                    <Layout>
                        <Header
                            className={cn({
                                'app-layout-header': true,
                                'app-layout-header--sticky': isMobile
                            })}
                            style={{
                                background: colorBgContainer
                            }}
                        >
                            <Menu
                                mode='horizontal'
                                className='app-layout-wrap'
                                items={isMobile || isTable ? menuItems : null}
                            />
                        </Header>
                        <Content
                            className='app-layout-content'
                            style={{
                                background: location.pathname.includes('tools') ? colorBgLayout : '#fff'
                            }}
                        >
                            <ScrollToTop />
                            <Switch>
                                <Route
                                    exact
                                    path={`${match.path}chat/:alias?`}
                                    render={(props) => (
                                        <ChatPage
                                            setDocToken={setDocToken}
                                            {...props}
                                            ctrlRef={ctrlRef}
                                            collapsed={setCollapsed}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path={`${match.path}library`}
                                    render={(props) => <LibraryPage {...props} setDocToken={setDocToken} />}
                                />
                                <Route exact path={`${match.path}art/image-generator`} component={ArtPage} />
                                <Route
                                    exact
                                    path={`${match.path}art/image-templates/:alias?`}
                                    component={ImageTemplates}
                                />
                                <Route exact path={`${match.path}art/image-upscale`} component={ImageUpscalePage} />
                                <Route exact path={`${match.path}art/history`} component={ArtHistoryPage} />
                                <Route exact path={`${match.path}video/image-to-video`} component={ImageToVideoPage} />
                                <Route exact path={`${match.path}video/history`} component={ImageToVideoHistoryPage} />
                                <Route exact path={`${match.path}speech/just-speak`} component={JustSpeakPage} />
                                <Route exact path={`${match.path}speech/history`} component={SpeechHistoryPage} />
                                <Route exact path={`${match.path}video/:id`} component={SingleVideoPage} />
                                <Route path={`${match.path}books/create`} component={BookPage} />
                                <Route
                                    path={`${match.path}document/editor`}
                                    render={(props) => (
                                        <DocumentEditor {...props} docToken={docToken} setDocToken={setDocToken} />
                                    )}
                                />
                                <Route
                                    path={`${match.path}document/history`}
                                    render={(props) => (
                                        <DocumentHistory {...props} docToken={docToken} setDocToken={setDocToken} />
                                    )}
                                />
                                <Route exact path={`${match.path}tools/:alias?`} component={ToolsPage} />
                                <Route exact path={`${match.path}settings/team`} component={TeamPage} />
                                <Route exact path={`${match.path}settings/profile`} component={ProfilePage} />
                                <Route exact path={`${match.path}settings/organization`} component={OrganizationPage} />
                                <Route exact path={`${match.path}settings/billing`} component={BillingPage} />
                                <Route path={`${match.path}knowledge-base`} component={KnowledgeBase} />
                                <Route path='*'>
                                    <Redirect to='/chat' />
                                </Route>
                            </Switch>
                        </Content>
                    </Layout>
                    {dashboardContext?.state?.modals?.billing ? <BillingUpdate context={dashboardContext} /> : null}
                    {dashboardContext?.state?.modals?.billingUpgradeModal ? (
                        <BillingUpdateModal context={dashboardContext} />
                    ) : null}
                </Layout>
            </DashboardProvider>
        </ConversationProvider>
    );
}
