import React from 'react';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { extractVideoId } from '../../../../../../../../utils/youtubeCode';
import { EMBEDDING_STATUS } from '../../../../constants';
import style from './index.module.scss';

export const VideoItem = ({ item, actions }) => {
    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(item.url);
    };

    const onTrain = async () => {
        await actions.video.train(item._id);
    };

    return (
        <div className={style.knowledge_base__video__single}>
            <iframe
                src={`https://www.youtube.com/embed/${extractVideoId(item.url)}`}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
            />
            <div className={style.knowledge_base__video__single__copy}>
                <CopyOutlined onClick={handleCopyClick} />
                <span>{item.url}</span>
            </div>
            <div className={style.knowledge_base__video__single__bottom}>
                <DeleteOutlined />
                <div className={style.knowledge_base__video__single__status}>
                    {EMBEDDING_STATUS.TRAINED === item.status ? <span className='trained'>Trained</span> : null}
                    {EMBEDDING_STATUS.NOT_TRAINED === item.status ? <button onClick={onTrain}>Train</button> : null}
                    {EMBEDDING_STATUS.TRAINING === item.status ? <span className='training'>Training</span> : null}
                    {EMBEDDING_STATUS.FAILED === item.status ? (
                        <Tooltip title='Please try another video'>
                            <button className='failed'>Error</button>
                        </Tooltip>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
