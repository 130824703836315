import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Documents } from '../Documents';
import { Videos } from '../Videos';

export const TabsComponent = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.path}/documents`} component={Documents} />
            <Route path={`${match.path}/videos`} component={Videos} />
        </Switch>
    );
};
