import React, { useEffect } from 'react';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table, Typography, Upload } from 'antd';
import { uploadImage } from '../../../ImageUpscalePage/hooks';
import { EMBEDDING_STATUS } from '../../constants';
import { useKnowledgeBase } from '../../hooks';
import { beforeUpload } from '../../utils/beforeUpload';
import { DocView } from './components/DocView';
import styles from './index.module.scss';

const validMimeTypes = ['application/pdf', 'text/plain', 'text/csv', 'text/x-csv'];

export const Documents = () => {
    const { actions, state } = useKnowledgeBase();

    useEffect(() => {
        (async () => {
            await actions.document.list();
        })();
    }, []);

    const onRemove = async (item) => {
        await actions.document.remove(item._id);
    };

    const onTrain = async (item) => {
        await actions.document.train(item._id);
    };

    const columns = [
        {
            title: '',
            dataIndex: 'view',
            key: 'view',
            render: (text, record) => <EyeOutlined onClick={() => actions.document.modal(true, record)} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: '',
            dataIndex: 'train',
            key: 'train',
            render: (text, record) => {
                if (EMBEDDING_STATUS.TRAINED === record.status) {
                    return (
                        <button onClick={() => onTrain(record)} className={styles.train}>
                            Re-Train
                        </button>
                    );
                }
                if (EMBEDDING_STATUS.NOT_TRAINED === record.status) {
                    return (
                        <button onClick={() => onTrain(record)} className={styles.train}>
                            Train
                        </button>
                    );
                }

                return null;
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (EMBEDDING_STATUS.TRAINED === record.status) {
                    return <span className={styles.trained}>Trained</span>;
                }
                if (EMBEDDING_STATUS.NOT_TRAINED === record.status) {
                    return <span className={styles.not_trained}>Not Trained</span>;
                }
                if (EMBEDDING_STATUS.TRAINING === record.status) {
                    return <span className={styles.training}>Training</span>;
                }
                if (EMBEDDING_STATUS.FAILED === record.status) {
                    return <span className='error'>Error</span>;
                }

                return null;
            }
        },
        {
            title: '',
            dataIndex: 'remove',
            key: 'remove',
            render: (text, record) => <DeleteOutlined onClick={() => onRemove(record)} />
        }
    ];

    const data = state.document.list?.map((doc) => {
        return {
            _id: doc._id,
            name: doc.fileName,
            type: doc.mimeType,
            status: doc.status,
            date: doc.createdAt,
            fileUrl: doc.fileUrl
        };
    });

    const onChangeFile = async ({ file, fileList }) => {
        const beforeUploadCheck = await beforeUpload(file, { maxFileSize: 10 }, validMimeTypes);

        if (beforeUploadCheck) {
            const formData = new FormData();
            formData.append('document', fileList[0]?.originFileObj);
            actions.document.create(formData);
        }
    };

    const beforeUploadCustom = () => {
        return false;
    };

    return (
        <div className={styles.knowledge_base__docs}>
            <Upload
                beforeUpload={beforeUploadCustom}
                maxCount={1}
                listType='picture'
                customRequest={uploadImage}
                onChange={onChangeFile}
                showUploadList={false}
                name='document'
                accept='.pdf, .csv, .txt'
            >
                {/* eslint-disable-next-line no-console */}
                <Button type='primary'>Upload Document</Button>
            </Upload>
            <Typography.Title className={styles.knowledge_base__docs__title} level={2}>
                Documents
            </Typography.Title>
            <Table className={styles.knowledge_base__docs__table} columns={columns} dataSource={data} />
            <DocView actions={actions} state={state} />
        </div>
    );
};
