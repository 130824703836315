import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { Modal } from 'antd';

export const DocView = ({ actions, state }) => {
    return (
        <Modal
            title='Share link to Chat'
            width={710}
            className='modal-component'
            centered
            open={state.document.modal}
            footer={null}
            onCancel={() => actions.document.modal(false)}
            okText='Copy Link'
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                <Viewer withCredentials fileUrl={state?.document?.record?.fileUrl} />
            </Worker>
        </Modal>
    );
};
