import React, { useEffect } from 'react';
import { Col, Input, List, Row, Typography } from 'antd';
import { useImageToVideo } from '../../hooks';
import { Share } from './components/Share';
import { SingleVideo } from './components/SingleVideo';
import './index.scss';

export default function HistoryPage() {
    const { state, actions } = useImageToVideo();

    useEffect(() => {
        actions.talk.list({ page: 1 });
    }, []);

    const onPageChange = async (page) => {
        await actions.talk.list({ page });
    };

    return (
        <div className='img-to-video__history'>
            <Row className='img-to-video__history__head' align='center' justify='space-between'>
                <Col span={12}>
                    <Typography.Title level={2}>Video Library</Typography.Title>
                </Col>
                <Col span={12}>
                    <Input.Search
                        className='img-to-video__search'
                        placeholder='Search Videos'
                        bordered={false}
                        onSearch={(value) => actions.talk.list({ page: 1, limit: 10, search: value })}
                        size='large'
                    />
                </Col>
            </Row>
            <List
                loading={state.loader.talk.list}
                pagination={{
                    showSizeChanger: false,
                    onChange: (pageNum) => onPageChange(pageNum),
                    total: state.history.pagination.total,
                    current: state.history.pagination.currentPage,
                    pageSize: 10,
                    hideOnSinglePage: true
                }}
                grid={{
                    gutter: 32,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 4
                }}
                dataSource={state.history.data}
                renderItem={(item) => {
                    return <SingleVideo item={item} actions={actions} removing={state.loader.talk.remove} />;
                }}
            />
            <Share state={state} actions={actions} />
        </div>
    );
}
