import React from 'react';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';

export default function PresenterCircle({ isSelected, isRemoving, src, alt, onClick, onRemove }) {
    return (
        <div
            className={cn('img-to-video__presenter__menu__item', {
                'img-to-video__presenter__menu__item--active': isSelected,
                'img-to-video__presenter__menu__item--remove': isSelected && onRemove
            })}
            onClick={onClick}
        >
            <img style={{ objectFit: 'cover' }} width={80} height={80} src={src} alt={alt} />
            {isSelected && onRemove ? (
                isRemoving ? (
                    <LoadingOutlined className='img-to-video__presenter__menu__item__delete' />
                ) : (
                    <DeleteOutlined className='img-to-video__presenter__menu__item__delete' onClick={onRemove} />
                )
            ) : null}
        </div>
    );
}
