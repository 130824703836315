import React, { useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { useLocation } from 'react-router-dom';
import Icon, { AudioOutlined } from '@ant-design/icons';
import { App, Button, Form, Input, Select, Tabs, Typography, Upload } from 'antd';
import ArrowBottom from '../../../../../../../../components/Icons/ArrowBottom/ArrowBottom';
import { BACKEND_URL, CDN_URL } from '../../../../../../../../config';
import { useBreakpoint } from '../../../../../../../../hooks/media';
import { useAuthContext } from '../../../../../../../AuthPage/context';
import { Presenter } from '../Presenter';
import { LoadingModal } from './components/LoadingModal';
import './index.scss';

const { Dragger } = Upload;

const LOGO_URL = `${CDN_URL}/avatars/just-think_logo.png`;

export const Settings = ({ form, imgToVideo }) => {
    const { state, actions } = imgToVideo;

    const { isMobile } = useBreakpoint();

    const auth = useAuthContext();

    const location = useLocation();

    const { notification } = App.useApp();

    useEffect(() => {
        auth.whoami();
    }, []);

    const org = auth.org.getCurrent();

    const maxTextLength = org.billingPlan.planType === 'free' ? 200 : 3875;

    useEffect(() => {
        if (state.voicesByLanguage['English (United States)']) {
            const voice = state.voicesByLanguage['English (United States)']?.find(() => true);

            form.setFieldsValue({
                voice: voice?.id
            });
        }

        if (location.state?.audio) {
            form.setFieldsValue({
                tab: 'audio'
            });
        }
    }, []);

    const onSubmit = async (values) => {
        function notify(message) {
            notification.error({
                placement: 'top',
                message
            });
        }

        if (!auth.profile.isEmailVerified) {
            return notify('Please verify your email!');
        }

        if (!values.presenterUrl) {
            return notify('Please choose a Presenter!');
        }

        const inputKey = values.tab === 'text' ? 'input' : 'audio_url';

        const inputVal = values.tab === 'text' ? values.text : values.audio;

        const provider =
            values.tab === 'text'
                ? {
                      type: state.voicesById[values.voice].provider,
                      voice_id: values.voice,
                      voice_language: values.language,
                      voice_name: state.voicesById[values.voice].name,
                      voice_config: values.style
                          ? {
                                style: values.style
                            }
                          : {}
                  }
                : null;

        const body = {
            title: values.title,
            talk: {
                source_url: values.presenterUrl,
                script: {
                    type: values.tab,
                    [inputKey]: inputVal,
                    provider,
                    subtitles: false,
                    ssml: false
                },
                config: {
                    logo: {
                        position: [10, 10],
                        url: LOGO_URL
                    },
                    stitch: true
                }
            }
        };

        try {
            await actions.talk.create(body);
        } catch (error) {
            if (error.response?.status !== 402) {
                notification.error({
                    placement: 'top',
                    message: 'Error',
                    description: error.response.data.message
                });
            }
        }
    };

    return (
        <React.Fragment>
            <Form
                className='img-to-video__settings'
                layout='vertical'
                form={form}
                initialValues={{
                    language: 'English (United States)',
                    tab: 'text',
                    audio: location.state?.audio || null
                }}
                onFinish={onSubmit}
            >
                <Form.Item label={<Typography.Title level={4}>Video Title</Typography.Title>} name='title'>
                    <Input placeholder='Input Video Title' />
                </Form.Item>
                {isMobile && (
                    <Form.Item>
                        <Presenter form={form} imgToVideo={imgToVideo} />
                    </Form.Item>
                )}
                <Tabs
                    defaultActiveKey={location.state?.audio ? 'audio' : 'text'}
                    onChange={(tab) => {
                        form.resetFields(['text', 'audio']);
                        form.setFieldsValue({
                            tab
                        });
                    }}
                    items={[
                        {
                            key: 'text',
                            label: 'Type Your Script',
                            children: (
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prev, cur) => {
                                        return prev.text?.length !== cur.text?.length || prev.tab !== cur.tab;
                                    }}
                                >
                                    {(form) => (
                                        <Form.Item
                                            rules={
                                                form.getFieldValue('tab') === 'text'
                                                    ? [{ required: true, message: 'Please fill in the text field!' }]
                                                    : []
                                            }
                                            name='text'
                                        >
                                            <div style={{ position: 'relative' }}>
                                                <Input.TextArea placeholder='Type your script here' rows={10} />
                                                <div className='img-to-video__settings__count'>
                                                    {form.getFieldValue('text')?.length || '0'}/{maxTextLength}
                                                </div>
                                            </div>
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            )
                        },
                        {
                            key: 'audio',
                            label: 'Upload Audio Voice',
                            children: (
                                <React.Fragment>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prev, cur) => {
                                            return prev.tab !== cur.tab;
                                        }}
                                    >
                                        {(form) => (
                                            <Form.Item
                                                rules={
                                                    form.getFieldValue('tab') === 'audio'
                                                        ? [{ required: true, message: 'Please attach audio file!' }]
                                                        : []
                                                }
                                                name='audio'
                                            >
                                                <Dragger
                                                    name='audio'
                                                    maxCount={1}
                                                    multiple={false}
                                                    accept='audio/*'
                                                    type='file'
                                                    action={`${BACKEND_URL}/video/voices`}
                                                    headers={{
                                                        'x-access-token': localStorage.getItem('BrainstormAuthToken')
                                                    }}
                                                    onChange={({ file }) => {
                                                        if (file.status === 'error') {
                                                            notification.error({
                                                                placement: 'top',
                                                                message: file.response.message
                                                            });
                                                        }

                                                        if (file.status === 'done') {
                                                            form.setFieldsValue({
                                                                audio: file.response.Location
                                                            });
                                                        }

                                                        if (file.status === 'removed') {
                                                            form.setFieldsValue({
                                                                audio: undefined
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <p className='ant-upload-drag-icon upload-audio-icon'>
                                                        <AudioOutlined />
                                                    </p>
                                                    <p className='ant-upload-text'>
                                                        Click or drag file to this area to upload
                                                    </p>
                                                    <p className='ant-upload-hint'>
                                                        Upload your own voice to create more realistic videos.
                                                    </p>
                                                </Dragger>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prev, cur) => {
                                            return prev.audio !== cur.audio;
                                        }}
                                    >
                                        {(form) => {
                                            const audio = form.getFieldValue('audio');

                                            if (!audio) return null;

                                            return (
                                                <AudioPlayer
                                                    src={audio}
                                                    preload='auto'
                                                    showJumpControls={false}
                                                    layout='horizontal-reverse'
                                                />
                                            );
                                        }}
                                    </Form.Item>
                                </React.Fragment>
                            )
                        }
                    ]}
                />
                <Form.Item name='tab' hidden noStyle>
                    <Input />
                </Form.Item>
                <Form.Item name='presenterUrl' hidden noStyle>
                    <Input />
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prev, curr) => {
                        return prev.tab !== curr.tab;
                    }}
                >
                    {(form) => {
                        if (form.getFieldValue('tab') === 'audio') {
                            return null;
                        }

                        return (
                            <React.Fragment>
                                <Form.Item
                                    label={<Typography.Title level={4}>Select Language</Typography.Title>}
                                    name='language'
                                >
                                    <Select
                                        suffixIcon={<Icon component={ArrowBottom} />}
                                        options={Array.from(state.languages).map((language) => ({
                                            label: language,
                                            value: language
                                        }))}
                                        onChange={() => {
                                            const voice = state.voicesByLanguage[form.getFieldValue('language')]?.find(
                                                () => true
                                            );

                                            form.setFieldsValue({
                                                voice: voice?.id,
                                                style: 'Choose styles'
                                            });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item noStyle shouldUpdate={(prev, cur) => prev.language !== cur.language}>
                                    {(form) => (
                                        <Form.Item
                                            rules={[{ required: true, message: 'Please select the voice!' }]}
                                            label={<Typography.Title level={4}>Voices</Typography.Title>}
                                            name='voice'
                                        >
                                            <Select
                                                suffixIcon={<Icon component={ArrowBottom} />}
                                                options={[
                                                    {
                                                        label: 'Female',
                                                        options: (
                                                            state.voicesByLanguage[form.getFieldValue('language')] || []
                                                        )
                                                            ?.filter((item) => item.gender === 'female')
                                                            ?.map((voice) => ({
                                                                label: voice.name,
                                                                value: voice.id
                                                            }))
                                                    },
                                                    {
                                                        label: 'Male',
                                                        options: (
                                                            state.voicesByLanguage[form.getFieldValue('language')] || []
                                                        )
                                                            ?.filter((item) => item.gender === 'male')
                                                            ?.map((voice) => ({
                                                                label: voice.name,
                                                                value: voice.id
                                                            }))
                                                    }
                                                ]}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item noStyle shouldUpdate={(prev, cur) => prev.voice !== cur.voice}>
                                    {(form) => (
                                        <Form.Item
                                            label={<Typography.Title level={4}>Styles</Typography.Title>}
                                            name='style'
                                        >
                                            <Select
                                                suffixIcon={<Icon component={ArrowBottom} />}
                                                placeholder='Choose styles'
                                                options={(
                                                    state.voicesById[form.getFieldValue('voice')] || []
                                                ).styles?.map((style) => ({
                                                    label: style,
                                                    value: style
                                                }))}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            </React.Fragment>
                        );
                    }}
                </Form.Item>
                <Form.Item>
                    <Button type='primary' block htmlType='submit'>
                        Generate & save
                    </Button>
                </Form.Item>
            </Form>
            <LoadingModal state={state} actions={actions} />
        </React.Fragment>
    );
};
