import React, { useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import PageLoader from '../../../../../../components/PageLoader/PageLoader';
import { useBreakpoint } from '../../../../../../hooks/media';
import { useImageToVideo } from '../../hooks';
import { Presenter } from './components/Presenter';
import { Settings } from './components/Settings';
import './index.scss';

export default function ImageToVideoPage() {
    const [form] = Form.useForm();

    const imgToVideo = useImageToVideo();

    const { isMobile } = useBreakpoint();

    useEffect(() => {
        imgToVideo.actions.voice.list();
        imgToVideo.actions.presenter.list();
    }, []);

    if (!imgToVideo.state.presenters.length) {
        return <PageLoader spinning />;
    }

    return (
        <Row className='img-to-video' gutter={[30, 30]}>
            {!isMobile && (
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
                    <Presenter form={form} imgToVideo={imgToVideo} />
                </Col>
            )}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                <Settings form={form} imgToVideo={imgToVideo} />
            </Col>
        </Row>
    );
}
