import React, { useEffect } from 'react';
import { Button, List, Typography } from 'antd';
import { useKnowledgeBase } from '../../hooks';
import { AddVideo } from './components/AddVideo';
import { VideoItem } from './components/Item';
import styles from './index.module.scss';

export const Videos = () => {
    const { actions, state } = useKnowledgeBase();

    useEffect(() => {
        const fetchData = async () => {
            await actions.video.list();
        };

        fetchData();

        const intervalId = setInterval(fetchData, 60000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={styles.knowledge_base__video}>
            <Button onClick={() => actions.video.modal(true)} type='primary'>
                Upload Video
            </Button>
            <Typography.Title className={styles.knowledge_base__video__title} level={2}>
                Videos
            </Typography.Title>
            <List
                renderItem={(item) => <VideoItem state={state} actions={actions} item={item} />}
                dataSource={state.video.list}
                grid={{
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 4
                }}
            />
            <AddVideo actions={actions} state={state} />
        </div>
    );
};
