export const initState = {
    document: {
        list: [],
        modal: false,
        record: null
    },
    video: {
        list: [],
        modal: false
    }
};

export function knowledgeBaseReducer(draft, action) {
    switch (action.type) {
        case 'DOCUMENT_MODAL': {
            draft.document.modal = action.payload.status;
            draft.document.record = action.payload.record;
            break;
        }
        case 'DOCUMENT_CREATE_START': {
            break;
        }
        case 'DOCUMENT_CREATE_END': {
            draft.document.list = [...draft.document.list, action.payload];
            break;
        }
        case 'DOCUMENT_UPDATE': {
            draft.document.list = draft.document.list.map((doc) => {
                if (action.payload._id === doc._id) {
                    return action.payload;
                }

                return doc;
            });
            break;
        }
        case 'DOCUMENT_UPDATE_END': {
            draft.document.list = action.payload;
            break;
        }
        case 'DOCUMENT_LIST': {
            draft.document.list = action.payload;
            break;
        }
        case 'DOCUMENT_LIST_START': {
            draft.document.list = action.payload;
            break;
        }
        case 'DOCUMENT_LIST_END': {
            draft.document.list = action.payload;
            break;
        }
        case 'VIDEO_MODAL': {
            draft.video.modal = action.payload;
            break;
        }
        case 'VIDEO_LIST': {
            draft.video.list = action.payload;
            break;
        }
        case 'VIDEO_CREATE_END': {
            draft.video.list = [...draft.video.list, action.payload];
            break;
        }
        case 'VIDEO_TRAIN_END': {
            draft.video.list = draft.video.list?.map((video) => {
                if (video._id === action.payload._id) {
                    return { ...video, ...{ status: action.payload.status } };
                }

                return video;
            });
            break;
        }
        case 'DOC_TRAIN_END': {
            draft.document.list = draft.document.list?.map((doc) => {
                if (doc._id === action.payload._id) {
                    return { ...doc, ...{ status: action.payload.status } };
                }

                return doc;
            });
            break;
        }
        default: {
            return draft;
        }
    }
}
